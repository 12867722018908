// OrganizationSettings.jsx
import React, { useState } from 'react';
import './OrganizationSettingsView.css'; // Add CSS for sidebar styling

// Individual components for each section
import { Overview } from './Overview';
import { Users } from './Users';
import { Properties } from './Properties';

const OrganizationSettingsView = () => {
    // State to track the currently selected section
    const [activeSection, setActiveSection] = useState("Overview");

    // Function to handle sidebar item click
    const handleSidebarClick = (section) => {
        setActiveSection(section);
    };

    // Render content based on active section
    const renderContent = () => {
        switch (activeSection) {
            case "Overview":
                return <Overview />;
            case "Users":
                return <Users />;
            case "Properties":
                return <Properties />;
            default:
                return <Overview />;
        }
    };

    return (
        <div className="organization-settings-container">
            <aside className="sidebar">
                <ul>
                    <li 
                        className={activeSection === "Overview" ? "active" : ""} 
                        onClick={() => handleSidebarClick("Overview")}
                    >
                        Overview
                    </li>
                    <li 
                        className={activeSection === "Users" ? "active" : ""} 
                        onClick={() => handleSidebarClick("Users")}
                    >
                        Users
                    </li>
                    <li 
                        className={activeSection === "Properties" ? "active" : ""} 
                        onClick={() => handleSidebarClick("Properties")}
                    >
                        Properties
                    </li>
                </ul>
            </aside>
            <main className="content">
                {renderContent()}
            </main>
        </div>
    );
};

export default OrganizationSettingsView;
